<script setup>
import { useRoute, useRouter } from 'vue-router';
import dompurify from 'dompurify';
import { isArray, isEqual, keyBy, omit, sortBy } from 'lodash-es';
import { onMounted, watch } from 'vue';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';
import FamNormalFlowFilters from '~/forms-as-module/components/fam-normal-flow-filters.vue';
import { useFormBulkUpdateMethods } from '~/forms/composables/form-list-bulk-update.composable.js';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { getPageNameByRouteName } from '~/common/utils/common.utils';
import { useFormsStore } from '~/forms/store/forms.store';

const props = defineProps({
  active_tab_details: {},
  filter_refresh_key: {},
  filters_type: {},
  is_widget: {},
  group_by: {},
  widget_views: {},
  fam_overview_display_filters_ref: {},
  table_options: {},
  fam_view_store_id: {},
});
const $track_event = inject('$track_event');
const form_detail_store = useFormDetailStore();
const forms_store = useFormsStore();

form_detail_store.$onAction(async ({ args, name, after }) => {
  after(() => {
    if (['update_form_details', 'approve_form', 'reopen_form', 'save_form', 'submit_form'].includes(name))
      getData({});
  });
});

forms_store.$onAction(async ({ name, after }) => {
  after(async () => {
    if (['create_form', 'delete_form', 'archiveForms'].includes(name))
      await getData({});
  });
});

const {
  active_view,
  active_view_columns,
  getAdvanceDataFilters,
  active_view_conditional_formatting,
  active_view_conditional_formatting_map,
  display_filters,
  handleColumnResize,
  handleDisplayFilterConfiguration,
  handleConditionalFormatting,
  handleColumnsConfiguration,
  setActiveViewID,
} = useFamCustomView(
  props.is_widget
    ? {
        feature: 'widget_view',
        fam_view_store_id: props.fam_view_store_id,
      }
    : { feature: 'list_view' },
);

const route = useRoute();
const $toast = inject('$toast');
const $services = inject('$services');
const auth_store = useAuthStore();
const form_template_detail_store = useFormTemplateDetailStore();
const $additional_filter = ref(null);

const state = reactive({
  submissions: [],
  search: '',
  rows_data_with_formatting: null,
  re_render_table: 0,
  is_loading: false,
  pagination_config: {
    totalRows: 0,
    pageSize: 25,
    pageIndex: 1,
  },
  selected_rows: [],
  normal_flow_filters: null,
});
const $t = inject('$t');
const router = useRouter();
const default_grey = '#667085';
const table_instance = ref(null);

const {
  bulk_update_options,
  selected_forms,
  is_pdf_exporting,
  handleFormSelection,
  clearBulkSelection,
} = useFormBulkUpdateMethods({
  table_instance,
  is_loading: state.is_loading,
  refresh: async () => { await getData({}); },
});
const { formsPDFExportHandler } = useExportPDF();

const is_export_loading = ref(false);
const is_exporting_all = ref(null);
const export_type = ref(null);

const setting_items = computed(() => {
  return [
    ...(form_template_detail_store?.form_template_detail?.can_modify_template
      ? [{
          label: $t('Columns'),
          uid: 'columns',
          on_click: () => {
            handleColumnsConfiguration();
          },
        },
        {
          label: $t('Filters'),
          uid: 'filters',
          on_click: () => {
            handleDisplayFilterConfiguration();
          },
        },
        {
          label: $t('Conditional formatting'),
          uid: 'conditional_formatting',
          on_click: () => {
            handleConditionalFormatting();
          },
        }]
      : []),
    {
      label: `${$t('Export xlsx')} (${state.pagination_config?.totalRows})`,
      uid: 'export',
      on_click: () => {
        setExport(true, 'exportXlsx');
      },
    },
  ];
});

const bulk_update_menu_items = computed(() => {
  return [...bulk_update_options.value,
    {
      label: $t('Export xlsx'),
      on_click: () => {
        setExport(false, 'exportXlsx');
      },
    },
  ];
});

const columns = computed(() => {
  const columns = convertFieldsToColumnsFormat(active_view_columns.value);
  if (!props.is_widget)
    columns.unshift({
      accessorKey: 'select',
      header: '',
      id: 'select',
      size: 100,
      enableSorting: false,
      enableResizing: false,
      show_on_hover: 'true',
    });
  return columns;
});

watch(() => active_view.value, async (newVal, oldVal) => {
  if (
    (!isEqual(newVal?.data?.filter?.condition, oldVal?.data?.filter?.condition)
  || !isEqual(newVal?.data?.conditional_formatting, oldVal?.data?.conditional_formatting)
  || !isEqual(newVal?.data?.columns?.map(item => item.field), oldVal?.data?.columns?.map(item => item.field)))
  )
    await getData({}, !isEqual(newVal?.uid, oldVal?.uid));
  if (!newVal)
    await getData({});
}, { immediate: true });

function setExport(exporting_all, type) {
  export_type.value = type;
  is_exporting_all.value = exporting_all;
  is_export_loading.value = true;
}

function applyConditionalFormatting() {
  prepareTableFormattingData('column', [], state.submissions);
  state.re_render_table += 1;
}
function convertFieldsToColumnsFormat(fields) {
  return fields.map((item) => {
    return {
      ...item,
      id: item.uid,
      header: item.name,
      accessorKey: item.uid,
      size: item.size || 300,
      type: item.type,
      name: item.name,
      properties: item.properties,
    };
  });
}
function getStepColumns(step) {
  return convertFieldsToColumnsFormat(
    form_template_detail_store.form_template_detail.sections.reduce(
      (columns, section) => {
        if (String(section.step_index) === String(step.index))
          columns.push(...section.fields);

        return columns;
      },
      [],
    ),
  );
}
function getStepName(step_index) {
  return form_template_detail_store.form_template_detail.steps[step_index]?.name || '-';
}
function getSectionColumns(section) {
  return convertFieldsToColumnsFormat(section.fields);
}

function getPayload() {
  const filters = form_template_detail_store.is_template_flow
                   || props.is_widget
    ? {
        advanced_filters: getAdvanceDataFilters(props.fam_overview_display_filters_ref || $additional_filter, props.active_tab_details),
      }
    : {
        ...state.normal_flow_filters,
        ...props.active_tab_details.filters,
      };
  const payload = {
    page_number: state.pagination_config?.pageIndex || 1,
    page_size: state.pagination_config?.pageSize || state.pagination_config.pageSize,
    fields: active_view_columns.value.map(item => ({ key: item.uid })),
    filters: {
      ongoing: true,
      ...(
        (props.active_tab_details?.filters?.pending_approval || props.active_tab_details?.filters?.submitted_by)
          ? {}
          : { open: true }),
      template: route.params.template_uid,
      asset_uid: route.params.asset_id ? [route.params.asset_id] : [],
      ...(state.search ? { q: state.search } : {}),
      ...filters,
    },
    ...(active_view?.value?.data?.conditional_formatting
      ? {
          conditional_formatting: keyBy((active_view?.value?.data?.conditional_formatting || []).map((item) => {
            return {
              uid: item.uid,
              logic: { type: item.filter.condition.logic },
              rules: item.filter.condition.rules,
            };
          }), 'uid'),
        }
      : {}),
  };
  return payload;
}

async function getData({ pagination_state }, is_loading = true) {
  if (pagination_state)
    state.pagination_config = { ...state.pagination_config, ...pagination_state };
  try {
    state.is_loading = is_loading;
    const { data, headers } = await $services.forms.post({
      attribute: 'submissions/responses',
      body: getPayload(),
    });
    state.pagination_config.totalRows = headers['x-total-count'];
    state.submissions = data.submissions.map((item) => {
      return {
        id: item.uid,
        class: 'bg-gray-500',
        ...omit(item, ['values']),
        ...item.values.reduce((field_values_map, field_value) => {
          field_values_map[field_value.uid] = dompurify.sanitize(
            field_value?.raw_data || (field_value.value === '-' ? null : field_value.value),
            { ALLOWED_TAGS: [] },
          );
          return field_values_map;
        }, {}),
      };
    });
    applyConditionalFormatting();
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ getData ~ error:', error);
  }
}

async function exportDataToSpreadSheet(export_all = false) {
  try {
    const body = { ...getPayload() };
    if (export_all) {
      body.page_number = 1;
      body.page_size = Number.parseInt(state.pagination_config.totalRows);
    }
    else if (state.selected_rows?.length) {
      body.filters.form_uids = state.selected_rows.map(item => item.original.uid);
    }

    const { data, headers } = await $services.forms.get_submission_report({
      body,
    });
    const disposition = headers['content-disposition'];
    if (disposition?.includes('attachment')) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches?.[1])
        data.name = matches[1].replace(/['"]/g, '');
    }
    else {
      const timezone = auth_store.logged_in_user_details?.timezone;
      const format = 'MMMM DD, YYYY h mm A z';
      const formatted_date = dayjs().utc().tz(timezone).format(format);
      data.name = (`${form_template_detail_store?.form_template_detail?.name.toLowerCase().split(' ').join('_')}-${formatted_date}.xlsx`);
    }
    saveAs(data);
    const form = state.selected_rows.length ? state.selected_rows[0]?.original : state.submissions[0];
    const event_details = {
      count: 0,
      output_format: 'XLSX',
      view: getPageNameByRouteName(route.name),
      with_summary: false,
      integration: form?.properties?.integration?.type || null,
    };
    if (body.filters?.form_uids?.length)
      event_details.count = body.filters.form_uids.length;
    else
      event_details.count = body.page_size;
    $track_event('Exported', event_details);
  }
  catch (error) {
    is_export_loading.value = false;
    $toast({
      text: 'Couldn\'t download. Please try again',
      type: 'error',
    });
    logger.info('[DEBUG] fam-export.vue::97\n', error);
  }
}

function openFormSubmissionComponent(e) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: e.uid })) } });
}

defineExpose({ state, $additional_filter });

function handleSelection(e) {
  selected_forms.value = e.map(forms_list_row => forms_list_row.original);
  state.selected_rows = e;
}

async function prepareTableFormattingData(formatting_on, rules = [], responses = []) {
  try {
    const rows_data = responses;
    if (responses.length) {
      for (let index = 0; index < responses.length; index++) {
        const submission = responses[index];
        if (submission.matched_conditional_formats?.length)
          submission.matched_conditional_formats = sortBy(submission.matched_conditional_formats, [item => active_view_conditional_formatting_map.value[item]?.order_index]);
        for (const rule of submission.matched_conditional_formats) {
          const rule_data = active_view_conditional_formatting_map.value[rule]?.filter?.condition?.format;
          const columnHash = keyBy(columns.value, 'id');
          if (
            rows_data[index].format
          && Object.hasOwn(rows_data[index].format, rule_data?.apply_to)
          && rows_data[index].format[rule_data?.apply_to]?.formatted_by === 'column'
          && !rows_data[index].format[rule_data?.apply_to]?.process_another_rule_for_this
          )
            continue;

          if (rule_data)
            if (rule_data.apply_to === 'Entire row') {
              Object.keys(columnHash).forEach((key) => {
                if (rows_data[index].format?.[key]?.process_another_rule_for_this && rule_data?.background_color)
                  rows_data[index].format[key].background_color = rule_data?.background_color;
                else rows_data[index].format = {
                  [key]: { ...rule_data, formatted_by: 'row' },
                  ...rows_data[index].format,
                  ...(!rule_data.background_color && { process_another_rule_for_this: true }),
                };
                if (rows_data[index].format?.[key]?.background_color)
                  delete rows_data[index].format?.[key]?.process_another_rule_for_this;
              });

              break;
            }
            else {
              if (rows_data[index].format?.[rule_data.apply_to]?.process_another_rule_for_this && rule_data?.background_color)
                rows_data[index].format[rule_data.apply_to].background_color = rule_data?.background_color;
              else rows_data[index].format = {
                ...rows_data[index].format,
                [rule_data.apply_to]: {
                  ...rule_data,
                  ...(!rule_data.background_color && { process_another_rule_for_this: true }),
                  formatted_by: 'column',
                },
              };
              if (rows_data[index].format?.[rule_data.apply_to]?.background_color)
                delete rows_data[index].format?.[rule_data.apply_to]?.process_another_rule_for_this;
            }
          else
            continue;
        }
      }
      state.rows_data_with_formatting = rows_data;
    }
  }
  catch (err) {
    logger.log('🚀 ~ prepareTableFormattingData ~ err:', err);
  }
}

function formatTable(cell) {
  const columnId = cell.column.columnDef?.id;
  const rowIndex = cell.row.index;
  if (state.rows_data_with_formatting)
    if (Object.hasOwn(state.rows_data_with_formatting[rowIndex]?.format || {}, columnId)) {
      const format = state.rows_data_with_formatting[rowIndex]?.format[columnId];
      return {
        color: format?.text_color || '',
        backgroundColor: format?.background_color || '',
        fontStyle: format?.font_style?.includes('italic') ? 'italic' : '',
        fontWeight: format?.font_style?.includes('bold') ? 'bold' : '',
        textDecoration: format?.font_style?.includes('underline') ? 'underline' : '',
      };
    }
  return {};
}

function getDataWithFilters(data) {
  state.normal_flow_filters = data;
  getData({});
}

function setExpandCollapse() {
  state.is_loading = true;
  form_template_detail_store.set_list_collapsed();
  setTimeout(() => {
    state.is_loading = false;
  }, 10);
}

function resetTablePagination() {
  // resets pagination of table to default during filters
  state.pagination_config = { totalRows: 0, pageSize: 25, pageIndex: 1 };
  getData({});
}

function getProgressText(type) {
  switch (type) {
    case 'exportXlsx':
      return $t('Exporting to XLSX');
    case 'exportCsv':
      return $t('Exporting to CSV');
    case 'exportAllXlsx':
      return $t('Exporting to XLSX');
    default:
      return $t('Exporting to PDF');
  }
}

function getCompletedText(type) {
  switch (type) {
    case 'exportXlsx':
      return $t('Exported XLSX');
    case 'exportCsv':
      return $t('Exported CSV');
    case 'exportAllXlsx':
      return $t('Exported XLSX');
    default:
      return $t('Exported PDF');
  }
}

onMounted(() => {
  setActiveViewID(props.active_tab_details?.uid);
});

onUnmounted(() => {
  if (form_template_detail_store.is_list_collapsed)
    form_template_detail_store.set_list_collapsed();
});
</script>

<template>
  <div :class="{ 'mt-4': !is_widget }">
    <div
      v-if="!is_widget && form_template_detail_store.is_template_flow"
      class="flex justify-between gap-4"
    >
      <div class="flex gap-3">
        <HawkText
          v-if="form_template_detail_store.is_list_collapsed"
          :content="active_tab_details?.name"
          class="text-lg font-semibold flex-shrink-0"
        />
        <FormBulkUpdateDropdown
          v-if="selected_forms.length > 0"
          :selected_items="selected_forms"
          :bulk_action_options="bulk_update_menu_items"
          @clear="clearBulkSelection()"
        />
        <HawkDisplayFilters
          v-else
          ref="$additional_filter"
          :display_filters="display_filters"
          @apply="resetTablePagination()"
        />
      </div>
      <div class="flex gap-3 flex-shrink-0">
        <HawkButton icon type="text" @click="setExpandCollapse">
          <IconHawkMinimizeOne
            v-if="form_template_detail_store.is_list_collapsed"
          />
          <IconHawkExpandOne v-else />
        </HawkButton>
        <HawkSearchInput
          v-model="state.search"
          :placeholder="$t('Search')"
          class="flex-shrink-0"
          @update:modelValue="resetTablePagination"
        />
        <HawkMenu
          additional_trigger_classes="!ring-0 !focus:ring-0"
          :items="setting_items"
          additional_dropdown_classes="w-52 z-[999]"
        >
          <template #trigger>
            <HawkButton icon type="outlined">
              <IconHawkSettingsOne />
            </HawkButton>
          </template>
        </HawkMenu>
      </div>
    </div>
    <div v-else-if="!is_widget" class="flex justify-between gap-4">
      <FormBulkUpdateDropdown
        v-if="selected_forms.length > 0"
        :selected_items="selected_forms"
        :bulk_action_options="bulk_update_options"
        @clear="clearBulkSelection()"
      />
      <FamNormalFlowFilters v-else @save="getDataWithFilters" />
    </div>
    <HawkLoader v-if="state.is_loading" />
    <HawkIllustrations v-else-if="!state.submissions.length" type="no-data" for="fam" />
    <template v-else>
      <component
        :is="props?.table_options?.no_table_wrapper ? 'div' : TableWrapperVue"
        :class="{ 'scrollbar mt-4': props?.table_options?.no_table_wrapper }"
        container_class="mt-4 border"
        :style="props?.table_options?.wrapper_class || ''"
      >
        <HawkTable
          :key="state.re_render_table"
          :pagination_config="state.pagination_config"
          :data="state.submissions"
          :columns="columns"
          :is_gapless="true"
          :show_menu_header="false"
          :manual_pagination="true"
          scroll_to_top_on_pagination
          :format-table="formatTable"
          cell_height="40px"
          :is_loading="state.is_loading"
          @tableInstance="table_instance = $event"
          @pagination="getData($event)"
          @select-row="handleSelection"
          @rowClicked="openFormSubmissionComponent"
          @column-resized="
            (_resized_column, columns_widths) =>
              handleColumnResize(_resized_column, columns_widths, columns)
          "
        >
          <template v-for="column in columns" #[column.id]="value" :key="column.id">
            <div
              v-if="column?.option_type === 'assets'"
            >
              <HawkAssetName :uid="value.data.row.original?.[column?.id]" />
            </div>
            <div
              v-else-if="column?.option_type === 'members' || column?.option_type === 'member'"
            >
              <div v-if="value.data.row.original?.[column?.id] === '-' || !value.data.row.original?.[column?.id]?.length">
                -
              </div>
              <HawkMembers
                v-else
                :members="isArray(value.data.row.original?.[column.id]) ? value.data.row.original?.[column.id] : value.data.row.original?.[column.id].split(',')"
                type="badge"
                :max_badges_to_display="1"
                size="badge"
                :popover_name_truncate_length="20"
              />
            </div>
            <div v-else-if="column?.option_type === 'tags'">
              <HawkTagsName
                v-if="value.data.row.original?.[column.id]?.length"
                :tags="value.data.row.original[column.id]"
              />
              <div v-else>
                -
              </div>
            </div>
            <div v-else-if="column?.type === 'date' || column?.type === 'date_time'">
              <div v-if="value.data.row.original?.[column.id]">
                {{ $date(value.data.row.original?.[column.id], "DD MMM YYYY") }}
              </div>
              <div v-else>
                -
              </div>
            </div>
            <div v-else-if="column?.type === 'attachments'">
              <span v-if="value.data.row.original?.[column.id] > 0">{{ value.data.row.original?.[column.id] }} attachment(s)</span>
              <span v-else>-</span>
            </div>
            <div v-else-if="column?.type === 'boolean'">
              <span v-if="value.data.row.original?.[column.id]">
                <IconHawkCheckSquare class="text-blue-600" />
              </span>
              <span v-else>
                <IconHawkSquare class="text-gray-300" />
              </span>
            </div>
            <div v-else-if="column.id === 'priority'" class="capitalize">
              {{ value.data.row.original?.priority || "-" }}
            </div>
            <div v-else-if="value.data.row.original?.[column?.id]">
              <div v-if="column.id === 'status' || column.id === 'rolled_back_from'">
                <HawkBadge
                  v-if="value.data.row.original[column?.id]?.name?.length"
                  :custom_color="value.data.row.original[column?.id]?.color"
                >
                  {{ value.data.row.original[column?.id]?.name }}
                </HawkBadge>
                <div v-else>
                  -
                </div>
              </div>

              <div v-else>
                {{ value.data.row.original?.[column.id] || "-" }}
              </div>
            </div>
            <div v-else-if="column.id === 'reason'">
              {{ value.data.row.original?.message || "-" }}
            </div>
          </template>
        </HawkTable>
      </component>
    </template>
    <HawkExportToast
      v-if="is_export_loading"
      :progress_text="getProgressText(export_type)"
      :completed_text="getCompletedText(export_type)"
      :submit="() => exportDataToSpreadSheet(is_exporting_all)"
      close_after_download
      @cancel="is_export_loading = false; export_type = null"
      @close="is_export_loading = false; export_type = null"
    />
    <HawkExportToast
      v-if="is_pdf_exporting"
      v-bind="{
        ...(selected_forms.length > 1 ? {
          completed_text: $t('Export request received'),
          completed_subtitle: $t('You will receive an email to download once the export is completed.'),
        } : {}),
      }"
      :submit="() => formsPDFExportHandler(selected_forms, route.params?.template_uid, () => is_pdf_exporting = false)"
      @cancel="is_pdf_exporting = false; export_type = null"
      @close="is_pdf_exporting = false; export_type = null"
    />
  </div>
</template>
